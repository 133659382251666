import { Link } from 'react-router-dom';

import './home.page.css'


const HomePage = () => {
    return (
        <section>
            <div className='home-first-block'>
                <div className='home-first-block-content'>
                    <div className='home-first-block-content-item'>
                        <h2>Participe da Economia Circular e Lucre com Seus Resíduos!</h2>
                        <p>
                            Nossa plataforma inovadora facilita a conexão de pessoas com empresas de reciclagem, transformando seus resíduos em recursos valiosos e promovendo a sustentabilidade.
                        </p>

                        <div className='home-first-block-content-item-links'>
                            <Link to='https://storageecocollect.blob.core.windows.net/apk/EcoCollect.apk' target='blank'>Começar Agora</Link>
                            <Link to='/' className='home-first-block-simple-button'>Saber mais</Link>
                        </div>

                        <br />

                        <h6><strong>EcoCollect</strong> - Liderando a gestão sustentável de resíduos com inovação e eficiência</h6>

                    </div>
                    <div className='home-first-block-content-item'>
                        <img src='images/home-background2.png' alt='home background' />
                    </div>
                </div>
            </div>

            <div className='home-second-block'>
                <div className='home-second-block-content'>
                    <div className='home-second-block-content-item'>
                        <img src='images/logo-senac.png' alt='logo senac' />
                    </div>
                </div>
            </div>

            <div className='home-third-block'>
                <div className='home-third-block-content'>
                    <div className='home-third-block-header'>
                        <h2> <span className='White-Service' >NOSSOS SERVIÇOS </span> </h2>
                        <h2>Serviços especializados de coleta e compostagem de resíduos verdes</h2>
                        <p>Promovendo a sustentabilidade e a eficiência. com simplicidade e praticidade de serem integrados à sua rotina, contribuindo para um meio ambiente mais limpo e saudável.</p>
                    </div>
                    <div className='home-third-block-itens'>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-truck"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Serviços de Coleta</h1>
                                    <p>A EcoCollect oferece serviços flexíveis de coleta de resíduos verdes, adaptados às suas necessidades, promovendo sustentabilidade e eficiência na gestão de resíduos.</p>
                                </div>
                            </div>
                        </div>  
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-seedling"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Melhoria do Solo</h1>
                                    <p>Transformamos resíduos verdes em melhoradores de solo para jardins e agricultura, reduzindo a quantidade de resíduos em aterros e ajudando a diminuir as emissões de gases de efeito estufa.</p>
                                </div>
                            </div>
                        </div>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-calendar-days"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Agende uma Coleta</h1>
                                    <p> Facilitamos o agendamento de coletas de resíduos verdes de forma simples e rápida. Utilize nossa ferramenta para gerenciar sua coleta e contribuir para um meio ambiente mais sustentável.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='home-third-block-itens'>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-rocket"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Cultura</h1>
                                    <p>Impulsionar a reciclagem é crucial para a sustentabilidade, conscientizando sobre a separação de recicláveis, estimulando a reutilização e apoiando a transformação de resíduos em novos produtos.</p>
                                </div>
                            </div>
                        </div>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-hand-holding-dollar"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Ações de incentivo</h1>
                                    <p>A plataforma incentiva a reciclagem com um sistema de pagamento baseado no tipo e quantidade de resíduos coletados, Isso promove a participação ativa na gestão de resíduos.</p>
                                </div>
                            </div>
                        </div>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-rotate"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Conexão Empresarial</h1>
                                    <p>Conectamos empresas que podem reaproveitar resíduos, permitindo que pessoas os anunciem e empresas recicladoras os adquiram para reutilização.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='home-fourth-block'>
                <div className='home-fourth-block-content'>
                    <h3>Tem alguma dúvida ou podemos te ajudar em algo?</h3>
                    <h3>
                        <strong>Entre em contato!</strong>
                    </h3>

                    <Link to='/contato'>Contato</Link>
                </div>
            </div>
        </section>
    )
}

export default HomePage;