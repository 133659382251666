import './about.page.css'
import { Link } from 'react-router-dom';


const AboutPage = () => {
    return (
        <section className='about-page'>
            <div className='about-first-block'>
                <div className='about-first-block-item'>
                    <h2>
                        Nós construímos <span className='about-first-block-highlight'>pontes </span>
                        entre <span className='about-first-block-highlight'>empresas e recicladores</span>
                    </h2>
                </div>

                <div className='about-first-block-item'>
                    <p>

                        Uma plataforma que oferece praticidade para empresas que trabalham com reaproveitamento de materiais, gestão eficiente para pessoas recicladoras e logística reversa.
                    </p>
                </div>
            </div>

            <div className='about-second-block'>
                <img src='images/about_background.png' alt='illustrative-background' />
            </div>

            <div className='about-third-block'>
                <div className='about-third-block-content'>
                    <div className='about-third-block-text'>
                        <div className='about-third-block-text-title-item'>
                            <h2>Juntos nós somos mais sustentáveis</h2>
                        </div>

                        <div className='about-third-block-text-item'>
                            <h6>
                                Com uma equipe especializada em gestão de resíduos, oferecemos soluções eficientes para uma coleta sustentável,
                                promovendo impacto ambiental positivo e inovação.
                            </h6>
                            <br />
                            <br />
                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;A proposta deste projeto é desenvolver uma aplicação chamada EcoCollect, destinada a facilitar a coleta e reciclagem de resíduos. A aplicação permitirá aos usuários, sejam eles coletores individuais ou empresas coletoras, gerenciar e realizar coletas de maneira eficiente, promovendo a sustentabilidade e a responsabilidade ambiental. Por meio de uma interface, os usuários poderão solicitar, gerenciar e acompanhar coletas, além de visualizar históricos e gerenciar suas informações de perfil.
                            </p>
                            <h3>
                            "O EcoCollect oferece funcionalidades como cadastro de usuários, 
                            solicitação de coleta, gerenciamento de histórico e gerenciamento financeiro através de uma carteira digital."
                            </h3>
                        </div>
                    </div>

                    <div className='about-third-block-numbers'>
                        <div className='about-third-block-numbers-item'>
                            <p>No Brasil o número de empresas de reciclagem de resíduos chega há aproximadamente</p>
                            <h2>6000+</h2>
                        </div>
                        <div className='about-third-block-numbers-item'>
                            <p>No estado de São Paulo o número de empresas de reciclagem de resíduos chega há aproximadamente</p>
                            <h2>1700+</h2>
                        </div>
                        <div className='about-third-block-numbers-item'>
                            <p>O Brasil deixa de ganhar por ano com o descarte incorreto de resíduos reciclavéis aproximadamente</p>
                            <h2>R$ 14 BI</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className='about-fourth-block'>
                <div className='about-fourth-block-content'>
                    <h3>Tem alguma dúvida ou podemos te ajudar em algo?</h3>
                    <h3>
                        <strong>Entre em contato!</strong>
                    </h3>

                    <Link to='/contato'>Contato</Link>
                </div>
            </div>
        </section>
    )
}

export default AboutPage;