import { useState } from 'react';
import './contact.page.css'
import axios from 'axios';

const ContactPage = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendData = async () => {
        const url = 'https://api-ecocollectbr-dev.azurewebsites.net/api/v1/support';
        const data = {
            'firstName': firstName,
            'lastName': lastName,
            'phone': phone,
            'email': email,
            'message': message
        };
        
        if(fieldsIsValid()){
            try {
                const response = await axios.post(url, data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                if (response.status === 200) {
                    alert('Solicitação de Contato enviada com sucesso!');
                    window.location.href = '/';
                }
            } catch (error) {
                console.log(error);
                alert('Ocorreu algum erro ao tentar processar sua solicitação, por favor tente novamente mais tarde.');
             }
        }
    };

    const fieldsIsValid = () => {
        if(firstName === "" || firstName.length < 3 || firstName.length > 100){
            alert('O Nome não pode ser vazio e deve ter entre 3 e 100 caracteres.');
            return false;
        }

        if(lastName === "" || lastName.length < 3 || lastName.length > 100){
            alert('O Sobrenome não pode ser vazio e deve ter entre 3 e 100 caracteres.');
            return false;
        }

        if(phone === "" || phone.length < 11 || phone.length > 14){
            alert('O Telefone não pode ser vazio e deve ter entre 11 e 14 caracteres.');
            return false;
        }

        if(email === "" || email.length < 10 || email.length > 200 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
            alert('O Email não pode ser vazio, deve válido e ter entre 10 e 200 caracteres.');
            return false;
        }

        if(email === "" || message.length < 50 || message.length > 500){
            alert('A Mensagem não pode ser vazia e deve ter entre 50 e 500 caracteres.');
            return false;
        }

        return true;
    }

    return (
        <section>
            <div className='contact-first-block'>
                <div className='contact-first-block-content'>
                    <h2>Queremos ouvir você, nos dê um alô!</h2>
                    <br />
                    <form>
                        <div className='contact-first-block-form'>
                            <div className='contact-first-block-form-item'>
                                <label>Nome</label>
                                <br />
                                <input
                                    type='text'
                                    value={firstName}
                                    minLength={3}
                                    maxLength={100}
                                    onChange={(e) => setFirstName(e.target.value)} 
                                    placeholder='Digite seu nome...' />
                            </div>

                            <div className='contact-first-block-form-item'>
                                <label>Sobrenome</label>
                                <br />
                                <input 
                                    type='text' 
                                    placeholder='Digite seu sobrenome...'
                                    value={lastName} 
                                    minLength={3}
                                    maxLength={100}
                                    onChange={(e) => setLastName(e.target.value)}  />
                            </div>
                        </div>
                        <div className='contact-first-block-form'>
                            <div className='contact-first-block-form-item'>
                                <label>Telefone</label>
                                <br />
                                <input 
                                    type='number'
                                    pattern='()[0-9]{2} [0-9]{9}' 
                                    placeholder='(00) 000000000'
                                    value={phone} 
                                    minLength={11}
                                    maxLength={14}
                                    onChange={(e) => setPhone(e.target.value)}  />
                            </div>

                            <div className='contact-first-block-form-item'>
                                <label>Email</label>
                                <br />
                                <input 
                                    type='email' 
                                    placeholder='Digite seu email...'
                                    value={email} 
                                    minLength={10}
                                    maxLength={200}
                                    onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='contact-first-block-form'>
                            <div className='contact-first-block-form-item'>
                                <label>Mensagem</label>
                                <br />
                                <textarea 
                                    placeholder='Digite como podemos te ajudar!'
                                    value={message} 
                                    minLength={50}
                                    maxLength={500}
                                    onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                        </div>

                        <button type='button' onClick={sendData}>Enviar</button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactPage;