import './reviews.page.css'


const ReviewsPage = () => {
    return(
        <section>
            <div className='review-first-block'>
                <div className='review-first-block-content'>
                    <h2>O que nossos usuários dizem?</h2>
                    
                    <div className='review-first-block-itens'>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer' />
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                    </div>

                    <div className='review-first-block-itens'>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                    </div>

                    <div className='review-first-block-itens'>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                        <div className='review-first-block-item'>
                            <p>
                                "Plataforma eficiente e sustentável! Facilita a coleta de resíduos, incentiva a reciclagem e conscientiza sobre sustentabilidade." 
                            </p>

                            <img src='images/review-image.jpg' alt='reviewer'/>
                            <h5>Joana Silva</h5>
                            <h6>Coletora</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ReviewsPage;